<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-12"><h1>Error 404 - Pagina niet gevonden</h1></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "terms",
};
</script>

<style lang="scss" scoped>
</style>